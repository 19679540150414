import moment from "moment";
import "moment/locale/vi";

// This function converts the string to lowercase, then perform the conversion
function toLowerCaseNonAccentVietnamese(str) {
  str = str.toLowerCase();
  //     We can also use this instead of from line 11 to line 17
  //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
  //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
  //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
  //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
  //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
  //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
  //     str = str.replace(/\u0111/g, "d");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  str = str.replaceAll(" ", "_");
  return str;
}

// This function keeps the casing unchanged for str, then perform the conversion
function toNonAccentVietnamese(str) {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/Đ/g, "D");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
}

function chunkArray(array, chunkSize) {
  return array.reduce((result, item, index) => {
    // If the current index is at the start of a new chunk, create a new chunk array
    if (index % chunkSize === 0) {
      result.push([]);
    }

    // Add the current item to the latest chunk
    result[result.length - 1].push(item);

    return result;
  }, []);
}

const formatDateComponent = (dateString) => {
  // Set the locale to Vietnamese
  moment.locale("vi");

  // Custom format for day names in Vietnamese
  const dayMap = {
    "thứ hai": "Thứ hai",
    "thứ ba": "Thứ ba",
    "thứ tư": "Thứ tư",
    "thứ năm": "Thứ năm",
    "thứ sáu": "Thứ sáu",
    "thứ bảy": "Thứ bảy",
    "chủ nhật": "Chủ nhật",
  };

  // Format the date
  const date = moment(dateString);
  const formattedDate = `${dayMap[date.format("dddd")]}, Ngày ${date.format(
    "DD"
  )} Tháng ${date.format("MM")} Năm ${date.format("YYYY")}`;

  return formattedDate;
};

const waitting = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function isNumeric(numericValue) {
  if (!numericValue) return false;
  if (typeof numericValue === "string" && numericValue.includes(",")) {
    numericValue = numericValue.replace(",", ".");
  }
  return !isNaN(numericValue) && !isNaN(parseFloat(numericValue));
}

function checkCurrentTimeInRangeTest(startTime, endTime) {
  const currentTime = new Date(); // Lấy thời gian hiện tại
  const startTimeDate = new Date(startTime);
  const endTimeDate = new Date(endTime);
  return currentTime >= startTimeDate && currentTime <= endTimeDate;
}

function getMatchingLocalStorageKeys(key1, type) {
  const keys = Object.keys(localStorage); // Lấy tất cả key trong localStorage
  return keys?.filter((key) => key.includes(`ids_${key1}`)) || []; // Lọc các key hợp lệ
}

function removeMatchingLocalStorageKeys(key1, type, levels) {
  const keys = Object.keys(localStorage); // Lấy tất cả key trong localStorage

  const listKeys =
    keys?.filter((key) => key.includes(`ids_${key1}_${type}_${levels}`)) || [];
  listKeys.forEach((key) => {
    localStorage.removeItem(key); // Xóa các key hợp lệ
  });
}

function removeDuplicates(array) {
  return [...new Set(array)]; // Sử dụng Set để loại bỏ trùng lặp
}
export {
  waitting,
  toLowerCaseNonAccentVietnamese,
  toNonAccentVietnamese,
  chunkArray,
  formatDateComponent,
  isNumeric,
  checkCurrentTimeInRangeTest,
  getMatchingLocalStorageKeys,
  removeMatchingLocalStorageKeys,
  removeDuplicates,
};
