import axios from "axios";
import { authHeader } from "./AuthService";

// const API_URL_AUTH = "http://localhost:4000/api/exam";
const API_URL_AUTH = "https://pimath-server-edu.vercel.app/api/exam";

const insertExam = async (body) => {
  return await axios.post(API_URL_AUTH + "/mock-exam", body, {
    headers: authHeader(),
  });
};

const getExams = async (pageNumber) => {
  return await axios.get(
    API_URL_AUTH + `/mock-exam/?page=${pageNumber}&limit=6`,
    {
      headers: authHeader(),
    }
  );
};


const getExamById = async (examId) => {
  return await axios.get(API_URL_AUTH + `/mock-exam/${examId}`, {
    headers: authHeader(),
  });
};

const activeExam = async (examId, active) => {
  return await axios.post(
    API_URL_AUTH + `/mock-exam/${examId}/active`,
    { active },
    {
      headers: authHeader(),
    }
  );
};

const deleteExam = async (examId) => {
  return await axios.delete(API_URL_AUTH + `/mock-exam/${examId}`, {
    headers: authHeader(),
  });
};

const searchExam = async (query, pageNumber) => {
  return await axios.get(
    API_URL_AUTH + `/mock-exam-search?q=${query}&page=${pageNumber}&limit=6`,
    {
      headers: authHeader(),
    }
  );
};

const submitAssignmentTHPT = async (body) => {
  return await axios.post(API_URL_AUTH + `/exam-result-thpt`, body, {
    headers: authHeader(),
  });
};

const submitAssignment = async (body) => {
  return await axios.post(
    API_URL_AUTH + `/exam-result-submitassignment`,
    body,
    {
      headers: authHeader(),
    }
  );
};

const checkStatusSubmitAssignment = async (examId) => {
  return await axios.get(API_URL_AUTH + `/exam-result/status/${examId}`, {
    headers: authHeader(),
  });
};

const getExamResultbyId = async (examId, key) => {
  return await axios.get(
    API_URL_AUTH + `/exam-result?examId=${examId}&key=${key}`,
    {
      headers: authHeader(),
    }
  );
};

const getExamResults = async (examId, key) => {
  return await axios.get(
    API_URL_AUTH + `/exam-result-all?examId=${examId}&key=${key}`,
    {
      headers: authHeader(),
    }
  );
};

const checkSeeDetailedAnswers = async (examId) => {
  return await axios.get(
    API_URL_AUTH + `/check-see-detailed-answers/${examId}`,
    {
      headers: authHeader(),
    }
  );
};

const checkCorrectAnswers = async (examId) => {
  return await axios.get(API_URL_AUTH + `/check-correct-answers/${examId}`, {
    headers: authHeader(),
  });
};

export {
  insertExam,
  getExams,
  getExamById,
  submitAssignmentTHPT,
  submitAssignment,
  checkStatusSubmitAssignment,
  getExamResultbyId,
  getExamResults,
  activeExam,
  deleteExam,
  searchExam,
  checkSeeDetailedAnswers,
  checkCorrectAnswers,
};
