import React, { useState } from "react";
import CameraIcon from "@mui/icons-material/Camera";
import { Button } from "@mui/material";

const StartExamButton = ({ isStarted, examResult, handleStartExam }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  return (
    <>
      {!isStarted && (
        <div className="flex justify-center mt-10 mb-10">
          <Button
            variant="contained"
            startIcon={<CameraIcon />}
            size="large"
            onClick={openPopup}
            disabled={examResult?.maximumNumberOfExams <= 0}
            className="py-[20px] px-[40px]"
          >
            Làm Bài
          </Button>
        </div>
      )}

      {/* Overlay and Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-[800px] mx-4 relative">
            {/* Close button (X) */}
            <button
              onClick={closePopup}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            {/* Header */}
            <div className="text-center pt-6 pb-3">
              <h2 className="text-xl font-bold text-black">
                Hướng dẫn làm bài kiểm tra
              </h2>
              <p className="text-sm text-gray-600 mt-1">
                Hãy bình tĩnh, cẩn thận và làm bài một cách thông minh bạn nhé !
              </p>
            </div>

            {/* Content */}
            <div className="p-4">
              <div className="border border-blue-200 border-dashed rounded-lg p-4 bg-blue-50">
                <h3 className="text-center text-lg font-bold text-orange-500 mb-3">
                  Trước khi bạn bắt đầu hãy lưu ý 5 điểm sau:
                </h3>

                <ul className="space-y-2">
                  <li className="flex">
                    <span className="font-bold text-orange-500 mr-1">#1:</span>
                    <span className="text-[16px]">
                      Câu hỏi bao gồm 4 loại là loại trắc nghiệm (chọn một đáp
                      án), loại đúng sai (chọn <strong>Đ</strong> hoặc{" "}
                      <strong>S</strong>), loại trả lời ngắn (điền số, dấu .
                      thay cho dấu phẩy thập phân) và loại kéo thả (kéo đáp án
                      thích hợp vào ô)
                    </span>
                  </li>
                  <li className="flex">
                    <span className="font-bold text-orange-500 mr-1">#2:</span>
                    <span className="text-[16px]">
                      Thời gian làm bài kiểm tra sẽ được đếm lùi dần cho đến khi
                      kết thúc
                    </span>
                  </li>
                  <li className="flex">
                    <span className="font-bold text-orange-500 mr-1">#3:</span>
                    <span className="text-[16px]">
                      Hãy bỏ qua câu hỏi khó, bạn vẫn có thể quay lại làm tiếp
                      khi còn thời gian
                    </span>
                  </li>
                  <li className="flex">
                    <span className="font-bold text-orange-500 mr-1">#4:</span>
                    <span className="text-[16px]">
                      Hãy đánh dấu xem lại sau vào câu hỏi nếu bạn chưa chắc
                      chắn về đáp án
                    </span>
                  </li>
                  <li className="flex">
                    <span className="font-bold text-orange-500 mr-1">#5:</span>
                    <span className="text-[16px]">
                      Bài thi sẽ kết thúc khi hết thời gian hoặc bạn chọn nộp
                      bài
                    </span>
                  </li>
                  <li className="flex">
                    <span className="font-bold text-orange-500 mr-1">#6:</span>
                    <span className="text-[16px]">
                      Đáp án chi tiết sẽ được nhận khi đạt 7 điểm đối với tài
                      khoản thường, <br />4 điểm đối với tài khoản VIP
                    </span>
                  </li>
                </ul>

                {/* Character illustration - using placeholder */}
                <div className="absolute bottom-12 right-8 md:block hidden">
                  <div className="w-[12rem] h-[15rem] relative">
                    <svg
                      width="200"
                      height="200"
                      viewBox="0 0 101 112"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M78.0767 111.91H63.9218L62.7497 100.462C62.7497 99.4704 62.8399 98.3887 62.8399 97.7577C61.6678 98.6591 60.2595 104.336 58.817 111.908H42.8393L46.0495 85.1377H79.4517V93.0668L78.6002 100.462L78.0767 111.91Z"
                        fill="#584843"
                      />
                      <path
                        d="M62.8398 100.372L59.9632 111.911H58.7827C60.2252 104.339 61.6678 96.3293 62.8398 95.3377C62.8398 96.0589 62.8398 99.3808 62.8398 100.372Z"
                        fill="#181717"
                      />
                      <path
                        d="M75.9163 111.91L76.9046 85.1377L79.5444 85.7451V92.9123L79.2624 111.91H75.9163Z"
                        fill="#181717"
                      />
                      <path
                        d="M66.8047 29.9336V39.1282H59.051V30.204C62.7475 32.2773 65.6326 30.835 66.8047 29.9336Z"
                        fill="#F9C38A"
                      />
                      <path
                        d="M66.8047 26.7782V30.5642C65.5425 31.4656 62.7475 32.998 59.051 30.8346V26.2373L66.8047 26.7782Z"
                        fill="#E59553"
                      />
                      <path
                        d="M40.9887 50.5178C40.9887 50.5178 34.9322 56.2888 33.1341 56.83C32.8314 57.0871 22.0393 54.5205 19.4271 53.1826C19.2911 53.1227 19.19 53.0513 19.054 52.9914C18.4437 52.669 17.2075 51.977 16.83 51.4206C16.3397 50.6395 15.6845 50.2383 14.6231 49.4886C14.0167 48.7581 12.3309 45.9496 11.9029 45.3575C11.4748 44.7654 10.6183 46.5149 11.8324 48.3144C13.0622 50.161 12.8581 49.9552 12.8043 50.4969C12.7505 51.0385 8.9829 51.6012 7.90642 51.2755C6.8649 50.9383 3.04126 50.8476 2.46558 51.2755C1.74776 52.0363 2.30955 52.2848 2.30955 52.2848C2.30955 52.2848 7.19236 52.5698 7.66661 52.8324C5.71638 52.7951 1.36629 52.8324 1.88855 54.4145C1.99771 54.7452 3.67774 54.4469 4.09338 54.4145C4.54397 54.3705 6.85527 54.4347 6.99131 54.4946C6.12132 54.677 1.96357 54.4946 2.35473 56.0586C2.51443 56.425 3.97907 55.9798 4.68892 55.9295C5.51608 55.8708 6.12051 55.8574 6.99131 56.1352C5.46907 56.5088 2.96404 56.4988 3.6765 57.8352C3.85556 58.1428 6.53508 57.1205 7.90642 57.1205C11.4403 56.7235 14.1394 58.4561 17.3888 57.5749C18.8631 58.1436 20.7983 59.0499 21.999 60.0344C27.7027 63.3655 35.0113 65.1685 35.9125 65.0806C36.8137 64.9926 44.9135 58.1803 45.2242 54.7774C45.6049 51.3514 40.9887 50.5178 40.9887 50.5178Z"
                        fill="#F9C38A"
                      />
                      <path
                        d="M34.6969 54.93C36.1051 50.9399 44.267 36.6417 44.267 36.6417L58.7825 33.667H70.2327L83.8467 36.6417L82.479 57.7802L79.4517 83.5124H46.5652L45.2587 60.169C45.2587 60.169 43.0047 62.5127 40.8409 63.7747C38.5869 60.7099 34.6969 57.1243 34.6969 54.93Z"
                        fill="white"
                      />
                      <path
                        d="M91.5103 63.0535C90.3382 64.045 89.6169 64.7662 88.535 65.7578C85.8302 62.783 82.2239 58.2759 82.2239 58.2759L83.8468 36.6416C83.8468 36.6416 93.4036 51.0645 97.1001 57.5548C95.2969 59.4478 93.5839 61.3408 91.5103 63.0535Z"
                        fill="white"
                      />
                      <path
                        d="M81.4382 43.4004C81.4382 43.4004 79.7354 49.5498 78.8839 57.4965C78.8839 63.8065 76.9047 84.327 76.9047 84.327L79.4515 83.3232L82.2241 58.4425L88.2647 66.1181C88.2647 66.1181 90.699 63.8645 91.6006 63.0532C84.2076 54.8502 81.889 47.4568 81.4382 43.4004Z"
                        fill="#B5B5B5"
                      />
                      <path
                        d="M46.4065 81.9912L79.6074 82.1925L79.4515 85.7688H46.0633L46.4065 81.9912Z"
                        fill="#616161"
                      />
                      <path
                        d="M61.1695 40.7226L59.2762 37.2071L62.9727 33.9619L66.0381 37.2071L63.8743 40.9931L66.0381 65.2415L61.44 70.8304L57.2927 65.4218L61.1695 40.7226Z"
                        fill="#475270"
                      />
                      <path
                        d="M59.0219 30.7979C59.0219 30.7979 56.0306 32.7911 55.0389 34.3235C55.8503 36.487 58.8255 39.3715 58.8255 39.3715L63.063 33.963L59.0219 30.7979Z"
                        fill="#E6E6E6"
                      />
                      <path
                        d="M66.8495 30.8076C66.8495 30.8076 69.6035 32.1833 70.5952 33.7157C69.7838 35.8792 67.2102 39.3712 67.2102 39.3712L62.6944 33.9738L66.8495 30.8076Z"
                        fill="#E6E6E6"
                      />
                      <path
                        d="M47.6032 49.4414H55.8076V50.8837H47.6032V49.4414Z"
                        fill="#B5B5B5"
                      />
                      <path
                        d="M45.2588 40.7881V60.0787C43.736 61.7158 42.755 62.5058 40.8527 63.7474L38.5207 60.8575C38.5207 60.8575 42.0131 56.7434 43.0048 52.2362C44.4474 45.7459 44.1769 43.7628 45.2588 40.7881Z"
                        fill="#B5B5B5"
                      />
                      <path
                        d="M53.9422 20.6312C53.9422 20.6312 53.8619 17.519 52.4997 17.3567C51.1376 17.1944 50.4631 19.865 51.5531 21.5083C52.6432 23.1516 53.4419 25.0601 54.6891 22.9801C54.9468 21.4518 53.9422 20.6312 53.9422 20.6312Z"
                        fill="#F9C38A"
                      />
                      <path
                        d="M71.4679 21.4303C71.4679 21.4303 71.8345 18.3261 73.1862 18.2846C74.5597 18.2467 74.997 20.97 73.7546 22.4895C72.5341 24.0126 71.5527 25.8455 70.4856 23.6672C70.3836 22.1456 71.4679 21.4303 71.4679 21.4303Z"
                        fill="#F9C38A"
                      />
                      <path
                        d="M72.8929 16.0346C72.8929 16.0346 71.8027 24.3608 69.9051 26.4434C68.0112 28.5042 64.8531 30.4865 62.1941 30.1728C59.5097 29.8774 56.5161 28.0927 55.2809 26.38C54.263 24.9734 53.048 17.4261 53.2496 13.3061C53.4295 9.18246 55.0598 8.02032 59.6564 7.87478C64.2566 7.70741 73.0173 4.13252 72.8929 16.0346Z"
                        fill="#F9C38A"
                      />
                      <path
                        d="M69.629 24.7579C70.9052 21.0437 71.3711 15.7557 68.0961 14.7095C63.935 13.469 58.1772 16.2479 55.5368 9.17876C56.5413 8.40507 57.4289 7.92603 59.6637 7.83132C64.2565 7.70762 73.0172 4.13273 72.8929 16.0348C72.8929 16.0348 71.8026 24.361 69.9051 26.4436C68.0112 28.5044 64.853 30.4867 62.1941 30.173C62.0851 30.1547 61.9762 30.1363 61.8672 30.1179C65.3014 29.8213 68.4335 28.1265 69.629 24.7579Z"
                        fill="#E59553"
                      />
                      <path
                        d="M60.5534 16.2451C60.0319 15.4835 58.5906 14.9935 57.0243 15.1111C55.4544 15.2506 55.4588 16.5536 55.4324 16.9758C55.8485 16.1029 56.4311 15.5724 57.5204 15.8908C58.6097 16.2092 59.8724 16.961 60.2794 16.9399C60.6827 16.9405 60.6621 16.3981 60.5534 16.2451Z"
                        fill="#443226"
                      />
                      <path
                        d="M64.935 16.4447C65.5252 15.7358 67.0012 15.356 68.5447 15.6388C70.0883 15.9215 69.9801 17.228 69.9574 17.6284C69.6178 16.7179 69.0887 16.1346 67.9945 16.3768C66.8822 16.5934 65.54 17.2428 65.1478 17.1766C64.7301 17.1286 64.8184 16.6046 64.935 16.4447Z"
                        fill="#443226"
                      />
                      <path
                        d="M63.6464 22.4922C63.6464 22.4922 63.2081 21.9019 62.6452 21.7845C62.0823 21.6671 61.3577 22.3757 61.3647 22.4667C61.35 22.5541 62.9629 22.6913 63.6464 22.4922Z"
                        fill="#D7A572"
                      />
                      <path
                        d="M65.1826 24.4121C65.1826 24.4121 62.6996 25.0488 59.7 24.3633C60.6111 26.4031 61.4012 27.2997 62.662 27.2654C63.9302 27.1873 65.0326 24.9032 65.1826 24.4121Z"
                        fill="#871A1A"
                      />
                      <path
                        d="M59.9832 24.4119C62.431 24.9145 64.5101 24.5466 65.0444 24.4346C64.6476 24.7943 63.5961 25.4478 62.5424 25.4497C61.3979 25.4588 60.7988 25.2904 59.9832 24.4119Z"
                        fill="#F4F4F4"
                      />
                      <path
                        d="M53.9764 5.46164C54.232 4.27595 57.2577 2.95552 58.3816 2.99293C59.9657 3.0355 62.4327 2.07623 63.9944 2.38447C65.5342 2.68904 67.8985 4.18027 69.2628 4.99413C72.9677 5.2062 72.5674 7.74801 73.5004 9.24391C75.1665 11.9147 73.8728 16.8327 72.9824 18.295C72.4938 19.0659 72.0909 20.5248 72.0909 20.5248L71.6259 20.4913C71.6259 20.4913 71.516 18.6614 71.4892 17.624C71.4876 16.7031 70.2892 18.4261 69.2812 13.0736C59.4072 18.1185 54.7188 12.6568 54.7188 12.6568C54.5511 14.582 54.0822 14.7488 53.7207 15.5635C53.3592 16.3783 53.7326 20.0114 53.7326 20.0114L53.1403 20.0687C53.1403 20.0687 53.1815 17.8303 52.1579 17.3882C52.1032 15.8521 51.1045 6.14499 53.9764 5.46164Z"
                        fill="#312822"
                      />
                      <path
                        d="M68.5187 19.2497C68.4432 18.9002 68.2004 18.6122 67.8993 18.4043C67.5945 18.2182 67.2314 18.112 66.8753 18.0969C66.5193 18.0818 66.1522 18.1321 65.8321 18.3027C65.512 18.4733 65.2606 18.7677 65.1618 19.0878C65.3441 18.8042 65.6062 18.579 65.919 18.4521C66.2317 18.3252 66.5515 18.2893 66.8821 18.3226C67.2163 18.334 67.5395 18.411 67.8299 18.5498C68.0948 18.7067 68.3486 18.9291 68.5187 19.2497Z"
                        fill="#602700"
                      />
                      <path
                        d="M56.7293 18.7212C56.9153 18.4157 57.1955 18.216 57.4827 18.1072C57.7955 17.9803 58.1153 17.9444 58.4496 17.9559C58.7838 17.9674 59.1107 18.0225 59.3974 18.1831C59.6877 18.3219 59.9342 18.588 60.0862 18.883C60.007 18.5553 59.7933 18.2274 59.4885 18.0413C59.1874 17.8333 58.8206 17.749 58.4609 17.7557C58.1049 17.7406 57.7341 17.8128 57.4177 17.9615C57.1012 18.1103 56.8536 18.3829 56.7293 18.7212Z"
                        fill="#602700"
                      />
                      <path
                        d="M72.4541 22.3601C72.8583 21.9567 72.6554 21.698 72.2824 21.1186C72.1559 20.8054 72.4606 19.3973 72.8354 19.1686C74.4727 18.0974 73.903 21.0776 73.1695 22.5032C73.0382 22.7505 72.0951 23.6917 71.8274 23.1526C71.5815 22.6171 72.068 22.789 72.4541 22.3601Z"
                        fill="#E59553"
                      />
                      <path
                        d="M52.8572 21.4813C52.5057 21.0403 52.7024 20.804 53.1324 20.2478C53.2966 19.9387 53.1108 18.5152 52.7734 18.2562C51.232 17.0308 51.5486 20.0706 52.1267 21.5602C52.2387 21.8261 53.1013 22.8248 53.4112 22.3157C53.7212 21.8066 53.2086 21.9223 52.8572 21.4813Z"
                        fill="#E59553"
                      />
                      <path
                        d="M96.1325 58.5898C96.1325 58.5898 98.2963 61.9251 99.3782 64.359C100.46 66.7929 100.28 69.5873 99.3782 71.2099C98.4766 72.8324 96.0423 73.6437 93.4277 73.2831C89.8214 72.8324 84.4305 67.5066 83.2717 66.4908C82.2027 65.5538 85.8513 63.2172 87.0773 64.5451C88.3649 65.0602 89.2804 65.1703 89.2804 65.1703L96.1325 58.5898Z"
                        fill="#F9C38A"
                      />
                      <path
                        d="M92.9076 63.7713C86.8729 62.2675 86.6556 61.7841 82.8591 59.7764C80.6438 58.6048 77.5268 56.7509 77.388 56.6644C76.7611 56.2101 75.4882 55.2421 75.0718 54.5232C74.5262 53.5179 73.8489 52.9627 72.7435 51.9408C72.0877 50.9862 70.2021 47.3787 69.7331 46.6112C69.2641 45.8436 68.561 47.9073 69.901 50.2316C71.2602 52.6153 71.0427 52.3432 71.0332 53.0025C71.0237 53.6617 67.3585 53.9891 66.2723 53.4859C65.2195 52.972 61.4468 52.4925 60.9142 52.962C60.2683 53.8262 60.8415 54.1852 60.8415 54.1852C60.8415 54.1852 65.6729 55.005 66.161 55.3727C64.2375 55.1392 59.9565 54.766 60.5978 56.7571C60.7318 57.1732 62.3624 56.9691 62.7691 56.9694C63.2093 56.9588 65.4906 57.2602 65.6294 57.3467C64.7872 57.4867 60.6781 56.8626 61.1888 58.8188C61.3755 59.2836 62.7821 58.8786 63.4771 58.8852C64.287 58.8928 64.8812 58.9346 65.761 59.3592C64.2919 59.671 61.8241 59.4175 62.633 61.1254C62.834 61.52 65.3908 60.524 66.7413 60.6561C70.1896 60.5094 72.1403 62.2607 75.2695 61.4926C76.7671 62.3323 81.9007 66.0984 85.692 68.8676C91.5763 73.5031 94.0797 73.1387 94.9601 73.1176C95.5535 68.9956 94.894 66.2716 92.9076 63.7713Z"
                        fill="#F9C38A"
                      />
                      <path
                        d="M91.2723 63.318C91.2723 63.318 94.8832 63.519 95.7014 63.8639C94.774 62.5294 93.3959 61.2035 93.3959 61.2035L91.2723 63.318Z"
                        fill="#E59553"
                      />
                      <path
                        d="M93.7957 73.3135C89.0737 72.8491 79.5444 64.2637 79.5444 64.2637C79.5444 64.2637 90.7168 71.0652 93.7957 71.5472C96.8747 72.0291 99.4201 71.1142 99.4201 71.1142C99.4201 71.1142 98.5178 73.7779 93.7957 73.3135Z"
                        fill="#E59553"
                      />
                      <path
                        d="M38.4959 60.916L39.6261 62.2036C39.6261 62.2036 37.0938 64.7217 35.8921 65.0507C34.6903 65.3798 26.4067 62.2036 26.4067 62.2036C26.4067 62.2036 33.7747 63.7775 35.5344 63.3339C37.2941 62.8904 38.4959 60.916 38.4959 60.916Z"
                        fill="#E59553"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            {/* Action buttons */}
            <div className="flex justify-center gap-3 p-4">
              <button
                onClick={handleStartExam}
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-8 rounded"
              >
                Bắt đầu
              </button>
              <button
                onClick={closePopup}
                className="bg-orange-400 hover:bg-orange-500 text-white font-semibold py-2 px-8 rounded"
              >
                Thoát
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StartExamButton;
