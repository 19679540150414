import { Checkbox, MenuItem, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import MathRenderer from "../MathRenderer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";

const QuestionList = ({
  questions,
  userAnswers,
  handleChangeUserAnswersTN,
  handleChangeUserAnswersDS,
  handleChangeUserAnswersTLN,
  handleChangeUserAnswersTLN_M,
  handleReviewClick,
  reviewedQuestions,
  onDragEnd,
}) => {
  return (
    <div className="xl:w-[80%] w-[100%] m-auto mt-10">
      {questions?.map((q, index) => {
        const { question, contentQuestions, type, imageUrl } = q;
        if (type === "TN") {
          return (
            <div
              key={index + 1}
              id={question}
              className="mx-auto relative mt-4 page p-[30px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
            >
              {/* Star and "Xem lại" (Positioned to Overlap Border) */}
              <div
                onClick={() => handleReviewClick(question)}
                className={`absolute top-0 right-3 flex items-center py-1 px-4 rounded-full text-sm cursor-pointer transition-all
                  ${
                    reviewedQuestions[question]
                      ? "bg-green-500 text-white"
                      : "bg-yellow-500 text-white"
                  }
                  transform translate-y-[-50%]`}
              >
                <span className="text-lg">★</span>
                <span className="ml-1">Xem lại</span>
              </div>

              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <span className="question-content">
                <MathRenderer content={contentQuestions} />
              </span>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                <div className="flex items-center">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white font-bold">
                    A
                  </div>
                  <span className="ml-3">
                    <MathRenderer content={q?.contentAnswerA} />
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-green-500 text-white font-bold">
                    B
                  </div>
                  <span className="ml-3">
                    <MathRenderer content={q?.contentAnswerB} />
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-yellow-500 text-white font-bold">
                    C
                  </div>
                  <span className="ml-3">
                    <MathRenderer content={q?.contentAnswerC} />
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="w-8 h-8 flex items-center justify-center rounded-full bg-red-500 text-white font-bold">
                    D
                  </div>
                  <span className="ml-3">
                    <MathRenderer content={q?.contentAnswerD} />
                  </span>
                </div>
                <div className="mt-5">
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Chọn đáp án"
                    color="success"
                    focused
                    name={question}
                    onChange={handleChangeUserAnswersTN}
                    value={userAnswers[question] || ""}
                    className="md:w-[50%] w-[100%] text-center"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {["A", "B", "C", "D"].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
          );
        }

        if (type === "DS") {
          return (
            <div
              key={index + 1}
              id={question}
              className="mx-auto relative mt-4 page p-[30px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
            >
              {/* Star and "Xem lại" (Positioned to Overlap Border) */}
              <div
                onClick={() => handleReviewClick(question)}
                className={`absolute top-0 right-3 flex items-center py-1 px-4 rounded-full text-sm cursor-pointer transition-all
                  ${
                    reviewedQuestions[question]
                      ? "bg-green-500 text-white"
                      : "bg-yellow-500 text-white"
                  }
                  transform translate-y-[-50%]`}
              >
                <span className="text-lg">★</span>
                <span className="ml-1">Xem lại</span>
              </div>
              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <div className="overflow-x-auto md:max-w-[100%] max-w-[400px] m-auto">
                <table className="table-contaiter styled-table">
                  <thead>
                    <tr>
                      <th rowSpan="2" className="question">
                        Mệnh đề
                      </th>
                      <th colSpan="2" className="text-center">
                        Nội dung
                      </th>
                      <th rowSpan="2" className="correct">
                        Đúng
                      </th>
                      <th rowSpan="2" className="wrong ">
                        Sai
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {["a", "b", "c", "d"].map((option, index) => {
                      const content = [
                        q?.contentYA,
                        q?.contentYB,
                        q?.contentYC,
                        q?.contentYD,
                      ][index];
                      if (content) {
                        return (
                          <tr key={option}>
                            <td className="question">{option})</td>
                            <td className="statement" colSpan="2">
                              <MathRenderer content={content} />
                            </td>
                            <td className="correct">
                              <Checkbox
                                onChange={() =>
                                  handleChangeUserAnswersDS(
                                    question,
                                    option,
                                    true
                                  )
                                }
                                checked={userAnswers[question][option] === "D"}
                              />
                            </td>
                            <td className="wrong">
                              <Checkbox
                                onChange={() =>
                                  handleChangeUserAnswersDS(
                                    question,
                                    option,
                                    false
                                  )
                                }
                                checked={userAnswers[question][option] === "S"}
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        }

        if (type === "KT") {
          return (
            <div
              key={index + 1}
              id={question}
              className="mx-auto relative mt-4 page p-[30px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
            >
              {/* Star and "Xem lại" (Positioned to Overlap Border) */}
              <div
                onClick={() => handleReviewClick(question)}
                className={`absolute top-0 right-3 flex items-center py-1 px-4 rounded-full text-sm cursor-pointer transition-all
                  ${
                    reviewedQuestions[question]
                      ? "bg-green-500 text-white"
                      : "bg-yellow-500 text-white"
                  }
                  transform translate-y-[-50%]`}
              >
                <span className="text-lg">★</span>
                <span className="ml-1">Xem lại</span>
              </div>
              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={q?.contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="container mx-auto p-6">
                  <div key={q.question} className="mb-8">
                    <Droppable droppableId={q.question}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="p-2 rounded mt-2 min-h-[50px] lg:w-[50%] w-[100%] border-2 border-dashed border-teal-600"
                        >
                          <div className="flex flex-wrap lg:space-x-2">
                            {q?.items?.map((item, index) => (
                              <Draggable
                                key={item?.id}
                                draggableId={item?.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={
                                      "bg-blue-500 text-white p-2 w-16 rounded text-center mr-2 lg:mt-0 mt-2"
                                    }
                                  >
                                    <MathRenderer content={item?.content} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>

                    <div className="mt-4">
                      {q?.contentY1 && (
                        <div className="flex items-center space-x-4 mb-4">
                          <div className="flex">
                            <strong>1) </strong>&nbsp; &nbsp;
                            <MathRenderer content={q?.contentY1} />
                          </div>
                          <Droppable droppableId="slot1">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                              >
                                {q?.answers?.slot1 && (
                                  <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                    <MathRenderer
                                      content={q?.answers?.slot1?.content}
                                    />
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                      {q?.contentY2 && (
                        <div className="flex items-center space-x-4 mb-4">
                          <div className="flex">
                            <strong>2) </strong>&nbsp; &nbsp;
                            <MathRenderer content={q?.contentY2} />{" "}
                          </div>

                          <Droppable droppableId="slot2">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                              >
                                {q?.answers?.slot2 && (
                                  <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                    <MathRenderer
                                      content={q?.answers?.slot2?.content}
                                    />
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}

                      {q?.contentY3 && (
                        <div className="flex items-center space-x-4 mb-4">
                          <div className="flex">
                            <strong>3) </strong>&nbsp; &nbsp;
                            <MathRenderer content={q?.contentY3} />
                          </div>

                          <Droppable droppableId="slot3">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                              >
                                {q?.answers?.slot3 && (
                                  <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                    <MathRenderer
                                      content={q?.answers?.slot3?.content}
                                    />
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}

                      {q?.contentY4 && (
                        <div className="flex items-center space-x-4 mb-4">
                          <div className="flex">
                            <strong>4) </strong>&nbsp; &nbsp;
                            <MathRenderer content={q?.contentY4} />
                          </div>
                          <Droppable droppableId="slot4">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                              >
                                {q?.answers?.slot4 && (
                                  <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                    <MathRenderer
                                      content={q?.answers?.slot4?.content}
                                    />
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </DragDropContext>
            </div>
          );
        }

        if (type === "TLN") {
          return (
            <div
              key={index + 1}
              id={question}
              className="mx-auto relative mt-4 page p-[30px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
            >
              {/* Star and "Xem lại" (Positioned to Overlap Border) */}
              <div
                onClick={() => handleReviewClick(question)}
                className={`absolute top-0 right-3 flex items-center py-1 px-4 rounded-full text-sm cursor-pointer transition-all
                  ${
                    reviewedQuestions[question]
                      ? "bg-green-500 text-white"
                      : "bg-yellow-500 text-white"
                  }
                  transform translate-y-[-50%]`}
              >
                <span className="text-lg">★</span>
                <span className="ml-1">Xem lại</span>
              </div>

              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={q?.contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                <div className="mt-5">
                  <TextField
                    label="Đáp án *"
                    type="text"
                    color="success"
                    focused
                    name={question}
                    onChange={handleChangeUserAnswersTLN}
                    className="text-center"
                    value={userAnswers[question] || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }

        if (type === "TLN_M") {
          return (
            <div
              key={index + 1}
              id={question}
              className="mx-auto relative mt-4 page p-[30px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
            >
              {/* Star and "Xem lại" (Positioned to Overlap Border) */}
              <div
                onClick={() => handleReviewClick(question)}
                className={`absolute top-0 right-3 flex items-center py-1 px-4 rounded-full text-sm cursor-pointer transition-all
                  ${
                    reviewedQuestions[question]
                      ? "bg-green-500 text-white"
                      : "bg-yellow-500 text-white"
                  }
                  transform translate-y-[-50%]`}
              >
                <span className="text-lg">★</span>
                <span className="ml-1">Xem lại</span>
              </div>

              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={q?.contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              {q?.contentY1 && (
                <div className="mt-4 mb-4">
                  <div className="flex">
                    <strong>1.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY1} />
                  </div>

                  <div className="mt-5">
                    <TextField
                      label="Đáp án *"
                      type="text"
                      color="success"
                      focused
                      name={question + "-1."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["1."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {q?.contentY2 && (
                <div className="mb-4">
                  <div className="flex">
                    <strong>2.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY2} />
                  </div>

                  <div className="mt-5">
                    <TextField
                      label="Đáp án *"
                      type="text"
                      color="success"
                      focused
                      name={question + "-2."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["2."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {q?.contentY3 && (
                <div className="mb-4">
                  <div className="flex">
                    <strong>3.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY3} />
                  </div>

                  <div className="mt-5">
                    <TextField
                      label="Đáp án *"
                      type="text"
                      color="success"
                      focused
                      name={question + "-3."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["3."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {q?.contentY4 && (
                <div className="">
                  <div className="flex">
                    <strong>4.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY4} />
                  </div>
                  <div className="mt-5">
                    <TextField
                      label="Đáp án *"
                      type="text"
                      color="success"
                      focused
                      name={question + "-4."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["4."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

export default QuestionList;
