import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { removeMatchingLocalStorageKeys } from "../common/common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  setOpen,
  setIsStartedQuiz,
  topic,
  level,
  type,
}) {
  const handleDoicocaumoi = () => {
    setOpen(false);
    setIsStartedQuiz(false);
  };

  const handleThilai = () => {
    let levels = [];
    level.forEach((e) => {
      if (e === "Tất cả") {
        levels.push(0);
      } else if (e === "VDT") {
        levels.push(1);
      } else if (e === "VD") {
        levels.push(2);
      } else if (e === "VDC") {
        levels.push(3);
      }
    });
    levels = levels.sort((a, b) => a - b);
    setOpen(false);
    setIsStartedQuiz(false);
    localStorage.removeItem(`ids_${topic?.key}_${type}_${levels}`);
    // removeMatchingLocalStorageKeys(topic?.key, type, levels);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Bạn có muốn thi lại các câu hỏi?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ngân hàng câu hỏi với chuyên đề và mức độ mà bạn đã chọn đã hết, vui
            lòng đợi câu hỏi mới hoặc thi lại
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDoicocaumoi}>Đợi câu hỏi mới</Button>
          <Button onClick={handleThilai}>Thi lại</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
