import React, { useState, useEffect, useRef } from "react";
import {
  DialogContent,
  DialogTitle,
  DialogImage,
  DialogClose,
  DialogDescription,
  DialogContainer,
} from "./LinearDialog.js";
import { ChevronsRight } from "lucide-react";
import { handleQuestionBank } from "../services/QuestionBankService.js";
import MathRenderer from "../MathRenderer.js";
import {
  Checkbox,
  CircularProgress,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getMatchingLocalStorageKeys,
  isNumeric,
  removeDuplicates,
} from "../common/common.js";
import { Bounce, toast } from "react-toastify";
import AlertDialogSlide from "./AlertDialogSlide.js";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkIcon from "@mui/icons-material/Link";

const QuizBox = ({ setIsStartedQuiz, topic, level, type }) => {
  const [question, setQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [isShowAnswer, setIsShowAnswer] = useState(false);
  const [timeCountDown, setTimeCountDown] = useState(90);
  const [loading, setLoading] = useState(true);
  const timerRef = useRef(null); // Dùng để lưu trữ timer hiện tại
  const [ids, setIds] = useState([]);
  const [countQ, setCountQ] = useState(0);

  // Hàm dừng bộ đếm trước khi khởi tạo mới
  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const draggedItem = question?.items[source.index];

    // Nếu thả vào ô trống (slot)
    if (destination.droppableId.startsWith("slot")) {
      const slotKey = destination.droppableId;
      const previousItemInSlot = question.answers[slotKey];

      setQuestion((q) => {
        const newQuestion = { ...q };
        // Cập nhật ô trống với item được kéo thả vào
        newQuestion.answers[slotKey] = draggedItem;

        // Xóa item khỏi danh sách items
        newQuestion.items = newQuestion?.items.filter(
          (item) => item.id !== draggedItem.id
        );

        // Nếu có item trước đó trong ô trống, đưa nó trở lại danh sách items
        if (previousItemInSlot) {
          newQuestion.items.push(previousItemInSlot);
        }
        setUserAnswer((prevAnswer) => {
          let newAnswer = {};
          if (prevAnswer?.[source.droppableId]) {
            newAnswer = {
              ...prevAnswer,
              [source.droppableId]: {
                ...prevAnswer[source.droppableId],
                [slotKey]: draggedItem.id,
              },
            };
          } else {
            newAnswer = {
              ...prevAnswer,
              [source.droppableId]: {
                [slotKey]: draggedItem.id,
              },
            };
          }
          // localStorage.setItem("userAnswer", JSON.stringify(newAnswer));
          return newAnswer;
        });
        return newQuestion;
      });
    }
  };

  const fetchQuestionBank = async () => {
    setLoading(true);
    try {
      clearTimer();
      setIsStartedQuiz(true);
      setIsSubmit(false);
      setIsShowAnswer(false);
      let levels = [];
      level.forEach((e) => {
        if (e === "Tất cả") {
          levels.push(0);
        } else if (e === "VDT") {
          levels.push(1);
        } else if (e === "VD") {
          levels.push(2);
        } else if (e === "VDC") {
          levels.push(3);
        }
      });

      levels = levels.sort((a, b) => a - b);
      const keys = getMatchingLocalStorageKeys(topic?.key, type);
      let idsArr = [];
      keys.forEach((key) => {
        const idslocalStorage = localStorage.getItem(key);
        if (idslocalStorage) {
          idsArr.push(...JSON.parse(idslocalStorage));
        }
      });
      idsArr = removeDuplicates(idsArr);
      const resQuestionBank = await handleQuestionBank({
        topic,
        levels,
        ids: idsArr,
        type,
      });
      if (resQuestionBank.status === "204" || !resQuestionBank.data) {
        setOpen(true);
        setQuestion(null);
      } else if (
        resQuestionBank &&
        resQuestionBank.data &&
        resQuestionBank.data.data
      ) {
        let listIds = [];
        let questionData = resQuestionBank.data.data;
        const idslocalStorage = localStorage.getItem(
          `ids_${topic?.key}_${type}_${levels}`
        );
        if (idslocalStorage) {
          listIds = [
            ...JSON.parse(idslocalStorage),
            questionData?.question_uuid,
          ];
        } else {
          listIds.push(questionData?.question_uuid);
        }

        localStorage.setItem(
          `ids_${topic?.key}_${type}_${levels}`,
          JSON.stringify(removeDuplicates(listIds))
        );
        if (questionData?.level === 3) {
          countdown(300);
          setTimeCountDown(300);
        } else if (questionData?.level === 2) {
          countdown(180);
          setTimeCountDown(180);
        } else if (questionData?.level === 1) {
          countdown(90);
          setTimeCountDown(90);
        }
        if (questionData?.typeOfQuestion === "KT") {
          questionData.answers = {
            slot1: null,
            slot2: null,
            slot3: null,
            slot4: null,
          };
        }
        setQuestion(questionData);
        setCountQ(resQuestionBank.data.count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    clearTimer();
    fetchQuestionBank();
  }, []);

  const handleChangeUserAnswerTN = (event) => {
    const { name, value } = event.target;
    setUserAnswer((prevAnswers) => {
      const newAnswer = { ...prevAnswers, [name]: value };
      // localStorage.setItem("userAnswer", JSON.stringify(newAnswer));
      return newAnswer;
    });
  };

  const handleChangeUserAnswerDS = (event, option, isCorrect) => {
    const { name, value } = event.target;

    setUserAnswer((prevAnswers) => {
      let newAnswer = {};
      if (prevAnswers?.[name]) {
        newAnswer = {
          ...prevAnswers,
          [name]: {
            ...prevAnswers[name],
            [option]: isCorrect ? "D" : "S",
          },
        };
      } else {
        newAnswer = {
          ...prevAnswers,
          [name]: {
            [option]: isCorrect ? "D" : "S",
          },
        };
      }

      // localStorage.setItem("userAnswer", JSON.stringify(newAnswer));
      return newAnswer;
    });
  };

  const handleChangeUserAnswerTLN = (event) => {
    const { name, value } = event.target;
    if (!value || value === "-") {
      setUserAnswer((prevAnswers) => {
        const newAnswer = {
          ...prevAnswers,
          [name]: value,
        };
        // localStorage.setItem("userAnswer", JSON.stringify(newAnswer));
        return newAnswer;
      });
      return;
    }
    let numericValue = value;

    if (typeof numericValue === "string" && numericValue.includes(",")) {
      numericValue = numericValue.replace(",", ".");
    }

    if (isNumeric(numericValue)) {
      setUserAnswer((prevAnswers) => {
        const newAnswer = {
          ...prevAnswers,
          [name]: numericValue,
        };
        // localStorage.setItem(`userAnswer`, JSON.stringify(newAnswer));
        return newAnswer;
      });
    }
  };

  const handleChangeUserAnswerTLN_M = (event) => {
    const { name, value } = event.target;

    const arr = name.split("-");
    if (!value || value === "-") {
      setUserAnswer((prevAnswers) => {
        let newAnswer = {};
        if (prevAnswers?.[arr[0]]) {
          newAnswer = {
            ...prevAnswers,
            [arr[0]]: { ...prevAnswers[arr[0]], [arr[1]]: value },
          };
        } else {
          newAnswer = {
            ...prevAnswers,
            [arr[0]]: { [arr[1]]: value },
          };
        }

        // localStorage.setItem(`userAnswer`, JSON.stringify(newAnswer));
        return newAnswer;
      });
      return;
    }

    let numericValue = value;
    if (typeof numericValue === "string" && numericValue.includes(",")) {
      numericValue = numericValue.replace(",", ".");
    }
    if (isNumeric(numericValue)) {
      setUserAnswer((prevAnswers) => {
        let newAnswer = {};
        if (prevAnswers?.[arr[0]]) {
          newAnswer = {
            ...prevAnswers,
            [arr[0]]: { ...prevAnswers[arr[0]], [arr[1]]: numericValue }, // Lưu giá trị dưới dạng số
          };
        } else {
          newAnswer = {
            ...prevAnswers,
            [arr[0]]: { [arr[1]]: numericValue }, // Lưu giá trị dưới dạng số
          };
        }

        // localStorage.setItem(`userAnswer`, JSON.stringify(newAnswer));
        return newAnswer;
      });
    }
  };

  const handleAnswer = () => {
    if (question?.typeOfQuestion === "TN") {
      return (
        <div className="grid grid-cols-2 gap-4 mt-5 text-black">
          <div className="flex items-center">
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white font-bold">
              A
            </div>
            <span className="ml-3">
              <MathRenderer content={question?.contentAnswerA} />
            </span>
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-green-500 text-white font-bold">
              B
            </div>
            <span className="ml-3">
              <MathRenderer content={question?.contentAnswerB} />
            </span>
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-yellow-500 text-white font-bold">
              C
            </div>
            <span className="ml-3">
              <MathRenderer content={question?.contentAnswerC} />
            </span>
          </div>
          <div className="flex items-center">
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-red-500 text-white font-bold">
              D
            </div>
            <span className="ml-3">
              <MathRenderer content={question?.contentAnswerD} />
            </span>
          </div>
          <div className="mt-5">
            <TextField
              id="outlined-select-currency"
              select
              label="Chọn đáp án"
              color="success"
              focused
              name={question.question_uuid}
              onChange={handleChangeUserAnswerTN}
              value={userAnswer?.[question.question_uuid] || ""}
              className="md:w-[50%] w-[100%] text-center"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {["A", "B", "C", "D"].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      );
    }

    if (question?.typeOfQuestion === "DS") {
      return (
        <div className="overflow-x-auto md:max-w-[100%] max-w-[400px] m-auto">
          <table className="table-contaiter styled-table">
            <thead>
              <tr>
                <th rowSpan="2" className="question">
                  Mệnh đề
                </th>
                <th colSpan="2" className="text-center">
                  Nội dung
                </th>
                <th rowSpan="2" className="correct">
                  Đúng
                </th>
                <th rowSpan="2" className="wrong ">
                  Sai
                </th>
              </tr>
            </thead>
            <tbody>
              {["a", "b", "c", "d"].map((option, index) => {
                const content = [
                  question?.contentYA,
                  question?.contentYB,
                  question?.contentYC,
                  question?.contentYD,
                ][index];
                if (content) {
                  return (
                    <tr key={option}>
                      <td className="question">{option})</td>
                      <td className="statement" colSpan="2">
                        <MathRenderer content={content} />
                      </td>
                      <td className="correct">
                        <Checkbox
                          onChange={(e) =>
                            handleChangeUserAnswerDS(e, option, true)
                          }
                          name={question?.question_uuid}
                          checked={
                            userAnswer?.[question?.question_uuid]?.[option] ===
                            "D"
                          }
                        />
                      </td>
                      <td className="wrong">
                        <Checkbox
                          onChange={(e) =>
                            handleChangeUserAnswerDS(e, option, false)
                          }
                          name={question?.question_uuid}
                          checked={
                            userAnswer?.[question?.question_uuid]?.[option] ===
                            "S"
                          }
                        />
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      );
    }

    if (question?.typeOfQuestion === "KT") {
      return (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="container mx-auto p-6">
              <div key={question?.question_uuid} className="mb-8">
                <Droppable droppableId={question?.question_uuid}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="p-2 rounded mt-2 min-h-[50px] lg:w-[50%] w-[100%] border-2 border-dashed border-teal-600"
                    >
                      <div className="flex flex-wrap lg:space-x-2">
                        {question?.items?.map((item, index) => (
                          <Draggable
                            key={item?.id}
                            draggableId={item?.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={
                                  "bg-blue-500 text-white p-2 w-16 rounded text-center mr-2 lg:mt-0 mt-2"
                                }
                              >
                                <MathRenderer content={item?.content} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <div className="mt-4">
                  {question?.contentY1 && (
                    <div className="flex items-center space-x-4 mb-4">
                      <div className="flex">
                        <strong>1) </strong>&nbsp; &nbsp;
                        <MathRenderer content={question?.contentY1} />
                      </div>
                      <Droppable droppableId="slot1">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                          >
                            {question?.answers?.slot1 && (
                              <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                <MathRenderer
                                  content={question?.answers?.slot1?.content}
                                />
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                  {question?.contentY2 && (
                    <div className="flex items-center space-x-4 mb-4">
                      <div className="flex">
                        <strong>2) </strong>&nbsp; &nbsp;
                        <MathRenderer content={question?.contentY2} />{" "}
                      </div>

                      <Droppable droppableId="slot2">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                          >
                            {question?.answers?.slot2 && (
                              <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                <MathRenderer
                                  content={question?.answers?.slot2?.content}
                                />
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}

                  {question?.contentY3 && (
                    <div className="flex items-center space-x-4 mb-4">
                      <div className="flex">
                        <strong>3) </strong>&nbsp; &nbsp;
                        <MathRenderer content={question?.contentY3} />
                      </div>

                      <Droppable droppableId="slot3">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                          >
                            {question?.answers?.slot3 && (
                              <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                <MathRenderer
                                  content={question?.answers?.slot3?.content}
                                />
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}

                  {question?.contentY4 && (
                    <div className="flex items-center space-x-4 mb-4">
                      <div className="flex">
                        <strong>4) </strong>&nbsp; &nbsp;
                        <MathRenderer content={question?.contentY4} />
                      </div>
                      <Droppable droppableId="slot4">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                          >
                            {question?.answers?.slot4 && (
                              <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                <MathRenderer
                                  content={question?.answers?.slot4?.content}
                                />
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DragDropContext>
        </>
      );
    }

    if (question?.typeOfQuestion === "TLN") {
      return (
        <div className="grid grid-cols-2 gap-4 mt-5 text-black">
          <div className="mt-5">
            <TextField
              label="Đáp án"
              type="text"
              color="success"
              focused
              name={question?.question_uuid}
              onChange={handleChangeUserAnswerTLN}
              className="text-center"
              value={userAnswer?.[question?.question_uuid]}
            />
          </div>
        </div>
      );
    }

    if (question?.typeOfQuestion === "TLN_M") {
      return (
        <>
          {question?.contentY1 && (
            <div className="mt-4 mb-4">
              <div className="flex">
                <strong>1.</strong>&nbsp; &nbsp;
                <MathRenderer content={question?.contentY1} />
              </div>

              <div className="mt-5">
                <TextField
                  label="Đáp án"
                  type="text"
                  color="success"
                  focused
                  name={question?.question_uuid + "-1."}
                  onChange={handleChangeUserAnswerTLN_M}
                  className="text-center"
                  value={userAnswer?.[question?.question_uuid]?.["1."] || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          )}
          {question?.contentY2 && (
            <div className="mb-4">
              <div className="flex">
                <strong>2.</strong>&nbsp; &nbsp;
                <MathRenderer content={question?.contentY2} />
              </div>

              <div className="mt-5">
                <TextField
                  label="Đáp án"
                  type="text"
                  color="success"
                  focused
                  name={question?.question_uuid + "-2."}
                  onChange={handleChangeUserAnswerTLN_M}
                  className="text-center"
                  value={userAnswer?.[question?.question_uuid]?.["2."] || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          )}
          {question?.contentY3 && (
            <div className="mb-4">
              <div className="flex">
                <strong>3.</strong>&nbsp; &nbsp;
                <MathRenderer content={question?.contentY3} />
              </div>

              <div className="mt-5">
                <TextField
                  label="Đáp án"
                  type="text"
                  color="success"
                  focused
                  name={question?.question_uuid + "-3."}
                  onChange={handleChangeUserAnswerTLN_M}
                  className="text-center"
                  value={userAnswer?.[question?.question_uuid]?.["3."] || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          )}
          {question?.contentY4 && (
            <div className="">
              <div className="flex">
                <strong>4.</strong>&nbsp; &nbsp;
                <MathRenderer content={question?.contentY4} />
              </div>
              <div className="mt-5">
                <TextField
                  label="Đáp án"
                  type="text"
                  color="success"
                  focused
                  name={question?.question_uuid + "-4."}
                  onChange={handleChangeUserAnswerTLN_M}
                  className="text-center"
                  value={userAnswer?.[question?.question_uuid]?.["4."] || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          )}
          {question?.contentY5 && (
            <div className="">
              <div className="flex">
                <strong>5.</strong>&nbsp; &nbsp;
                <MathRenderer content={question?.contentY5} />
              </div>
              <div className="mt-5">
                <TextField
                  label="Đáp án"
                  type="text"
                  color="success"
                  focused
                  name={question?.question_uuid + "-5."}
                  onChange={handleChangeUserAnswerTLN_M}
                  className="text-center"
                  value={userAnswer?.[question?.question_uuid]?.["5."] || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          )}
          {question?.contentY6 && (
            <div className="">
              <div className="flex">
                <strong>6.</strong>&nbsp; &nbsp;
                <MathRenderer content={question?.contentY6} />
              </div>
              <div className="mt-5">
                <TextField
                  label="Đáp án"
                  type="text"
                  color="success"
                  focused
                  name={question?.question_uuid + "-6."}
                  onChange={handleChangeUserAnswerTLN_M}
                  className="text-center"
                  value={userAnswer?.[question?.question_uuid]?.["6."] || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          )}
        </>
      );
    }
  };

  const handleSubmitExamTest = async () => {
    if (
      question?.typeOfQuestion === "TN" ||
      question?.typeOfQuestion === "TLN"
    ) {
      const userAns = userAnswer?.[question?.question_uuid];
      const correctAns = question?.answer;
      if (userAns == correctAns) {
        toast("🦄 Bạn đã chọn đáp án đúng!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        toast.error(
          `🐧 Bạn đã chọn đáp án sai !!!! 🐧 Đáp án đúng: ${correctAns}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          }
        );
      }
    }

    if (
      question?.typeOfQuestion === "DS" ||
      question?.typeOfQuestion === "KT" ||
      question?.typeOfQuestion === "TLN_M"
    ) {
      Object.keys(question?.answer || {}).forEach((key) => {
        const userAns = userAnswer?.[question?.question_uuid]?.[key];
        const correctAns = question?.answer?.[key];
        if (userAns == correctAns) {
          toast("🦄 Bạn đã chọn đáp án đúng!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.error(
            `🐧 Bạn đã chọn đáp án sai !!!! 🐧 Đáp án đúng: [${key}] ${correctAns}`,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }
          );
        }
      });
    }
    setIsSubmit(true);
  };

  const countdown = (durationInSeconds) => {
    clearTimer(); // Xóa bộ đếm trước đó
    let remainingTime = durationInSeconds;
    timerRef.current = setInterval(() => {
      setTimeCountDown(remainingTime);
      remainingTime--;

      if (remainingTime < 0) {
        clearTimer(); // Dừng bộ đếm
        toast.info(`🐧 Hết thời gian!!!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }, 1000);
  };

  return (
    <DialogContainer
      className="fixed inset-0 flex items-center justify-center py-[5rem]"
      setIsStartedQuiz={setIsStartedQuiz}
    >
      <DialogContent
        style={{
          borderRadius: "24px",
        }}
        setIsStartedQuiz={setIsStartedQuiz}
        className="relative flex h-full mx-auto flex-col overflow-y-auto border bg-gray-300 hover:bg-gray-200 
  xl:w-[1200px] lg:w-[900px] md:w-[750px] sm:w-[500px] w-[400px] max-h-[90vh]"
      >
        {loading ? (
          <CircularProgress className="m-auto" />
        ) : (
          <>
            <div className="p-6">
              <div className="flex mb-5">
                {" "}
                <DialogTitle className="text-5xl text-zinc-950  flex items-center lg:flex-row flex-col">
                  <span>ID: {question?.question_uuid || "PIMATH"}</span>
                  <div className="flex lg:w-auto w-full justify-start mt-5 lg:mt-0 lg:ml-[2.5rem] ml-0">
                    <span className="text-[1.3rem] text-center flex items-center mr-4">
                      <Tooltip title={`Thời gian còn lại`} placement="top">
                        <AccessAlarmIcon className="text-[2.5rem] mr-2" />
                      </Tooltip>

                      <span className="text-emerald-600 font-weight-bold">
                        {timeCountDown}
                      </span>
                    </span>
                    <span className="text-[1.3rem] text-left flex items-center mr-4">
                      <Tooltip title={`Số lượt thi`} placement="top">
                        <VisibilityIcon className="text-[2.5rem] mr-2" />
                      </Tooltip>

                      <span className="text-emerald-600 font-weight-bold">
                        {countQ}
                      </span>
                    </span>
                    <span className="text-[1.3rem] text-left flex items-center">
                      <Tooltip
                        title={`Báo cáo lỗi liên quan đến chức năng thực chiến Pimath`}
                        placement="top"
                      >
                        <a
                          href="https://docs.google.com/spreadsheets/d/1q-7W43_pCljWMBS71t3qLdg1hn8QEBr_SOHqYGYy0T4/edit?usp=sharing"
                          target="_blank"
                        >
                          <LinkIcon className="text-[2.5rem] mr-2 hover:text-emerald-600" />
                        </a>
                      </Tooltip>
                    </span>
                  </div>
                </DialogTitle>
              </div>
              <DialogDescription
                disableLayoutAnimation
                variants={{
                  initial: { opacity: 0, scale: 0.8, y: -40 },
                  animate: { opacity: 1, scale: 1, y: 0 },
                  exit: { opacity: 0, scale: 0.8, y: -50 },
                }}
              >
                <p className="mt-2 text-black ">
                  <MathRenderer content={question?.contentQuestion} />
                </p>
              </DialogDescription>
              {question?.imageUrlQuestion && (
                <DialogImage
                  src={question?.imageUrlQuestion}
                  alt=""
                  className="h-auto object-contain w-[40%] mx-auto mt-5"
                />
              )}
              {handleAnswer()}
            </div>
            <div className="w-full flex justify-between items-center mt-auto p-5">
              {isSubmit ? (
                <button
                  className="ml-5 mb-5 group cursor-pointer slide-anime px-5 py-3 rounded-full w-[145px]  bg-black text-white  flex justify-between items-center font-semibold text-right"
                  onClick={() => setIsShowAnswer(true)}
                >
                  Đáp án{" "}
                  <div className="group-hover:translate-x-2 transition-all">
                    <ChevronsRight />
                  </div>
                </button>
              ) : (
                <button
                  className="ml-5 mb-5 group cursor-pointer slide-anime px-5 py-3 rounded-full w-[145px] bg-black text-white  flex justify-between items-center font-semibold text-right"
                  onClick={handleSubmitExamTest}
                >
                  Nộp bài{" "}
                  <div className="group-hover:translate-x-2 transition-all">
                    <ChevronsRight />
                  </div>
                </button>
              )}

              <button
                className="mr-5 mb-5 group cursor-pointer slide-anime px-5 py-3 rounded-full w-[155px]  bg-black text-white  flex justify-between items-center font-semibold text-right"
                onClick={() => fetchQuestionBank()}
              >
                Tiếp tục{" "}
                <div className="group-hover:translate-x-2 transition-all">
                  <ChevronsRight />
                </div>
              </button>
            </div>
          </>
        )}

        <DialogClose
          className="text-zinc-50   bg-gray-400 p-4 hover:bg-gray-500 rounded-full "
          setIsStartedQuiz={setIsStartedQuiz}
          onClick={() => clearTimer()}
        />

        {isShowAnswer && (
          <div className="px-6 pb-6 sm:min-h-0 min-h-screen sm:overflow-visible overflow-auto">
            <h1 className="text-[1.3rem] text-center">Lời giải</h1>
            {question?.imageUrlAnswerQuestion && (
              <DialogImage
                src={question?.imageUrlAnswerQuestion}
                alt=""
                className="h-auto object-contain w-[40%] mx-auto mt-5"
              />
            )}
            <p className="mt-2 text-black ">
              <MathRenderer content={question?.contentAnswerQuestion} />
            </p>
          </div>
        )}
        <AlertDialogSlide
          open={open}
          setOpen={setOpen}
          setIsStartedQuiz={setIsStartedQuiz}
          topic={topic}
          level={level}
          type={type}
        />
      </DialogContent>
    </DialogContainer>
  );
};

export default QuizBox;
