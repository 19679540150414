import React, { useState } from "react";
import LoadingComponent from "./LoadingComponent";
import QuestionList from "./QuestionList";
import ExamActions from "./ExamActions";
import Countdown from "react-countdown";
import { Button } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import SendIcon from "@mui/icons-material/Send";
import ExamResult from "./ExamResult";
import CircularProgress from "@mui/material/CircularProgress";

const ExamContainer = ({
  examData,
  isStarted,
  isCompleted,
  questions,
  userAnswers,
  handleChangeUserAnswersTN,
  handleChangeUserAnswersDS,
  handleChangeUserAnswersTLN,
  handleChangeUserAnswersTLN_M,
  onDragEnd,
  renderer,
  handleSubmitExam,
  examResult,
  handleStartRetestExam,
  handleViewSelectedAnswerExam,
  examResults,
  maxScore,
  remainingTime,
  setRemainingTime,
  examId,
}) => {
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const localreviewedQuestions = localStorage.getItem(
    `reviewedQuestions_${examId}`
  );
  // Ensure useState is not called conditionally
  const [reviewedQuestions, setReviewedQuestions] = useState(
    localreviewedQuestions ? JSON.parse(localreviewedQuestions) : {}
  );

  const scrollToQuestion = (questionIndex, question) => {
    setCurrentQuestion(questionIndex);
    const element = document.getElementById(question);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const scrollToSubmit = () => {
    const element = document.getElementById("submit-button");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const isAnswered = (questionKey) => {
    const answer = userAnswers?.[questionKey];
    if (typeof answer === "string") {
      return answer.trim() !== "";
    }
    if (typeof answer === "object" && answer !== null) {
      return Object.values(answer).some((value) => value.trim() !== "");
    }
    return false;
  };

  const handleReviewClick = (questionId) => {
    setReviewedQuestions((prevState) => {
      const newReviewedQuestions = {
        ...prevState,
        [questionId]: !prevState[questionId], // Toggle the review state for the specific question
      };
      localStorage.setItem(
        `reviewedQuestions_${examId}`,
        JSON.stringify(newReviewedQuestions)
      );
      return newReviewedQuestions;
    });
  };

  if (!questions || questions.length === 0) {
    return null; // If there are no questions, return nothing
  }

  return (
    <>
      <div>
        {isStarted && !isCompleted && (
          <>
            <div className="w-full">
              <div className="mb-4 mt-5">
                <nav id="navi" className="nav-question">
                  <div className="flex justify-center">
                    <h5 className="text-[25px] text-center mr-3">
                      Thời gian còn lại:
                    </h5>
                    <span
                      className="flex justify-center items-center text-[25px]"
                      style={{
                        color: "#dc3545",
                      }}
                    >
                      <AccessAlarmIcon />{" "}
                      <Countdown
                        date={Date.now() + remainingTime}
                        renderer={renderer}
                        onTick={({ hours, minutes, seconds }) => {
                          setRemainingTime(
                            hours * 60 * 60 * 1000 +
                              minutes * 60 * 1000 +
                              seconds * 1000
                          );
                        }}
                      />
                    </span>
                  </div>
                </nav>
              </div>

              <QuestionList
                questions={questions}
                userAnswers={userAnswers}
                handleChangeUserAnswersTN={handleChangeUserAnswersTN}
                handleChangeUserAnswersDS={handleChangeUserAnswersDS}
                handleChangeUserAnswersTLN={handleChangeUserAnswersTLN}
                handleChangeUserAnswersTLN_M={handleChangeUserAnswersTLN_M}
                onDragEnd={onDragEnd}
                handleReviewClick={handleReviewClick}
                reviewedQuestions={reviewedQuestions}
              />

              {questions?.length > 0 ? (
                <div className="w-full text-center mb-10">
                  <Button
                    variant="contained"
                    startIcon={<SendIcon />}
                    size="large"
                    className="px-[40px] py-[18px]"
                    id="submit-button"
                    onClick={handleSubmitExam}
                  >
                    Nộp bài
                  </Button>
                </div>
              ) : (
                <CircularProgress className="flex m-auto mb-10" />
              )}
            </div>
            {/* Floating Question Navigator and Submit Button */}
            <div className="fixed bottom-4 right-4 flex flex-col gap-2 z-1000">
              {/* Nộp bài Button (on top) */}
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg"
                onClick={() => scrollToSubmit()}
              >
                Nộp bài
              </button>

              {/* Chọn câu hỏi Button (below) */}
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg"
                onClick={() => setIsNavigatorOpen(!isNavigatorOpen)}
              >
                Chọn câu hỏi
              </button>
            </div>
            {/* Floating Question Navigator */}
            {isNavigatorOpen && (
              <div className="fixed bottom-16 right-4 bg-white shadow-lg rounded-lg p-4 w-[18rem] max-h-96 overflow-y-auto z-1000">
                <h3 className="text-xl font-bold mb-3 text-center">Thống kê</h3>
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
                      <span>Đã trả lời</span>
                    </div>
                    <span>
                      {questions?.filter((q) => isAnswered(q.question))
                        .length || 0}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-yellow-400 mr-2"></div>
                      <span>Xem lại sau</span>
                    </div>
                    <span>
                      {Object.keys(reviewedQuestions)?.filter(
                        (q) => reviewedQuestions[q] === true
                      ).length || 0}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
                      <span>Chưa chọn</span>
                    </div>
                    <span>
                      {questions?.filter((q) => !isAnswered(q.question))
                        .length || 0}
                    </span>
                  </div>
                </div>

                <h3 className="text-lg font-bold mb-3 text-center mt-5">
                  Chọn câu hỏi
                </h3>
                <div className="grid grid-cols-5 gap-2">
                  {questions?.map((q, index) => {
                    const { question } = q;
                    const isReviewed = reviewedQuestions[question] === true; // Kiểm tra câu hỏi có trong danh sách "Xem lại" không

                    return (
                      <div className="relative">
                        <button
                          key={index + 1}
                          className={`w-10 h-10 text-sm font-bold flex items-center justify-center rounded-md border transition-all
                ${
                  currentQuestion === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 hover:bg-gray-300"
                }
                ${isAnswered(question) ? "font-extrabold bg-green-400" : ""}`}
                          onClick={() => scrollToQuestion(index + 1, question)}
                        >
                          {index + 1}
                        </button>

                        {/* ⭐️ Ngôi sao xuất hiện nếu câu hỏi được đánh dấu "Xem lại" */}
                        {isReviewed && (
                          <span className="absolute top-[-15px] right-0 text-yellow-500 text-lg">
                            ★
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}

        {isStarted && isCompleted && (
          <ExamActions
            startTime={examData?.startTime}
            endTime={examData?.endTime}
            examResult={examResult}
            handleStartRetestExam={handleStartRetestExam}
            handleViewSelectedAnswerExam={handleViewSelectedAnswerExam}
          />
        )}
        <ExamResult
          isCompleted={isCompleted}
          examResult={examResult}
          examResults={examResults}
          maxScore={maxScore}
        />
      </div>
    </>
  );
};

export default ExamContainer;
