import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { isNumeric } from "../common/common";

function ExamResult({
  loading,
  isCompleted,
  examResult,
  examResults,
  maxScore,
}) {
  if (!isCompleted) {
    return null;
  }
  return (
    <>
      {examResult && (
        <>
          <h1 className="text-center text-[25px] mb-10">
            KẾT QUẢ THI THỬ CỦA BẠN
          </h1>
          <div className="px-[15px] md:px-[115px] table-result">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Thứ hạng</StyledTableCell>
                    <StyledTableCell align="left">Tên</StyledTableCell>
                    <StyledTableCell align="left">Trường học</StyledTableCell>
                    <StyledTableCell align="left">Điểm</StyledTableCell>
                    <StyledTableCell align="left">Số câu đúng</StyledTableCell>
                    <StyledTableCell align="left">
                      Thời gian thi
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Thời gian nộp bài
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell
                      className={`${
                        ![1, 2, 3].includes(examResult?.ranking)
                          ? "md:pl-[39px] pl-[34px] py-[2px] pr-[16px]"
                          : "py-[2px] pr-[16px]"
                      }`}
                    >
                      {examResult?.ranking === 1 ? (
                        <img
                          src={"/images/top1.png"}
                          className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                          alt="top1"
                        />
                      ) : examResult?.ranking === 2 ? (
                        <img
                          src={"/images/top2.png"}
                          className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                          alt="top2"
                        />
                      ) : examResult?.ranking === 3 ? (
                        <img
                          src={"/images/top3.png"}
                          className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                          alt="top3"
                        />
                      ) : (
                        examResult?.ranking
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {examResult?.user?.username}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {examResult?.user?.school}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {examResult?.total_score} / {maxScore}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {examResult?.numberOfCorrectAnswers} / 100
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {examResult?.examCompledTime} phút
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {examResult?.updatedAt}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}

      {examResults?.length > 0 && (
        <div className="mt-[40px] mb-[40px]">
          <h1 className="text-center text-[25px] mb-10">
            KẾT QUẢ THI THỬ CHUNG
          </h1>
          <div className="px-[15px] md:px-[115px] table-result">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Thứ hạng</StyledTableCell>
                    <StyledTableCell align="left">Tên</StyledTableCell>
                    <StyledTableCell align="left">Trường học</StyledTableCell>
                    <StyledTableCell align="left">Điểm</StyledTableCell>
                    <StyledTableCell align="left">Số câu đúng</StyledTableCell>
                    <StyledTableCell align="left">
                      Thời gian thi
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Thời gian nộp bài
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {examResults.map((item, index) => (
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      key={index}
                    >
                      {/* Thứ hạng */}
                      <StyledTableCell
                        align="left"
                        className={`${
                          ![1, 2, 3].includes(item?.ranking)
                            ? "md:pl-[39px] pl-[34px] py-[2px] pr-[16px]"
                            : "py-[2px] pr-[16px]"
                        }`}
                        component="th"
                        scope="row"
                      >
                        {item?.ranking === 1 ? (
                          <img
                            src={"/images/top1.png"}
                            className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                            alt="top1"
                          />
                        ) : item?.ranking === 2 ? (
                          <img
                            src={"/images/top2.png"}
                            className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                            alt="top2"
                          />
                        ) : item?.ranking === 3 ? (
                          <img
                            src={"/images/top3.png"}
                            className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                            alt="top3"
                          />
                        ) : (
                          item?.ranking
                        )}
                      </StyledTableCell>
                      {/* Tên */}
                      <StyledTableCell align="left">
                        {item?.user?.username}
                      </StyledTableCell>
                      {/* Trường học */}
                      <StyledTableCell align="left">
                        {item?.user?.school}
                      </StyledTableCell>
                      {/* Điểm */}
                      <StyledTableCell align="left">
                        {item?.total_score} / {maxScore}
                      </StyledTableCell>
                      {/* Số câu đúng */}
                      <StyledTableCell align="left">
                        {item?.numberOfCorrectAnswers} / 100
                      </StyledTableCell>
                      {/* Thời gian thi */}
                      <StyledTableCell align="left">
                        {item?.examCompledTime} phút
                      </StyledTableCell>
                      {/* Thời gian nộp bài */}
                      <StyledTableCell align="left">
                        {item?.updatedAt}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#009879",
    color: "#ffffff !important",
    fontFamily: "'Potta One', system-ui !important",
    fontSize: "15px !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default ExamResult;
