import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkCorrectAnswers,
  checkSeeDetailedAnswers,
  getExamById,
} from "../services/MockExamService.js";
import NavbarComponent from "./NavbarComponent.js";
import HeroWrapComponent from "./HelloWorldComponent.js";
import FooterComponent from "./FooterComponent.js";
import LoadingComponent from "./LoadingComponent.js";
import { getUserInfoById } from "../services/UserService.js";
import { Checkbox, MenuItem, TextField, Tooltip, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import MathRenderer from "../MathRenderer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import QuizIcon from "@mui/icons-material/Quiz";

const ViewSelectedAnswer = (props) => {
  const {} = props;
  const { examId } = useParams();
  const navigate = useNavigate();
  const [examData, setExamData] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCheckSeeDetailedAnswers, setIsCheckSeeDetailedAnswers] =
    useState(false);
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answers, setAnswers] = useState(null);

  const scrollToQuestion = (questionIndex, question) => {
    setCurrentQuestion(questionIndex);
    const element = document.getElementById(question);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const isAnswered = (questionKey) => {
    const answer = userAnswers?.[questionKey];
    if (typeof answer === "string") {
      return answer.trim() !== "";
    }
    if (typeof answer === "object" && answer !== null) {
      return Object.values(answer).some((value) => value.trim() !== "");
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userPesponse = await getUserInfoById();
        const response = await getExamById(examId);
        if (
          userPesponse &&
          userPesponse.data &&
          userPesponse.data.data &&
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setExamData(data);
          if (!data.active && userPesponse.data.data.role !== 1) {
            navigate("/exam-papers");
            return;
          }

          localStorage.setItem(
            `questions_${examId}`,
            JSON.stringify(data?.questions)
          );
          setQuestions(data?.questions);

          const localUserAnswers = localStorage.getItem(
            `userAnswers_${examId}`
          );
          if (!localUserAnswers) {
            setUserAnswers((prevAnswers) => {
              let newPrevAnswers = { ...prevAnswers };
              Array.isArray(data?.questions) &&
                data?.questions.forEach((q) => {
                  if (q.type === "TN") {
                    newPrevAnswers = { ...newPrevAnswers, [q.question]: "" };
                  } else if (q.type === "DS") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: { a: "", b: "", c: "", d: "" },
                    };
                  } else if (q.type === "KT") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: {
                        slot1: "",
                        slot2: "",
                        slot3: "",
                        slot4: "",
                      },
                    };
                  } else if (q.type === "TLN_M") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: { "1.": "", "2.": "", "3.": "", "4.": "" },
                    };
                  } else if (q.type === "TLN") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: null,
                    };
                  } else {
                    toast.error(`Câu hỏi không hợp lệ: ${q?.question}`);
                  }
                });
              return newPrevAnswers;
            });
          } else {
            setUserAnswers(JSON.parse(localUserAnswers));
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true);
      try {
        const response = await checkSeeDetailedAnswers(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          if (data) setIsCheckSeeDetailedAnswers(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true);
      try {
        const response = await checkCorrectAnswers(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          if (data) setAnswers(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSeeDetailedAnswers = () => {
    if (!examData?.link_answer) {
      toast.error("Đáp án chưa được biên soạn bởi Admin, chờ nhé!!");
      return;
    }
    window.open(examData?.link_answer, "_blank", "noopener,noreferrer");
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <div className="text-center mt-10">
        <Tooltip
          title={`Đáp án chi tiết sẽ được nhận khi đạt 7 điểm đối với tài
                      khoản thường, 4 điểm đối với tài khoản VIP`}
          placement="top"
        >
          <span>
            {" "}
            <Button
              className=""
              variant="contained"
              startIcon={<QuizIcon />}
              size="large"
              disabled={isCheckSeeDetailedAnswers === false}
              onClick={handleSeeDetailedAnswers}
            >
              Xem đáp án chi tiết
            </Button>
          </span>
        </Tooltip>
      </div>
      <section
        className="ftco-section ftco-no-pb ftco-no-pt test-online"
        id="chapter-section"
      >
        <div className="Exam">
          <div>
            <div className="w-full">
              <div className="xl:w-[80%] w-[100%] m-auto mt-10">
                {questions?.map((q, index) => {
                  const { question, contentQuestions, type, imageUrl } = q;
                  if (type === "TN") {
                    return (
                      <div
                        key={index + 1}
                        id={question}
                        className="mx-auto relative mt-4 page md:p-[30px] p-[10px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
                      >
                        <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                          {question}:
                        </h2>
                        <span className="question-content">
                          <MathRenderer content={contentQuestions} />
                        </span>
                        <div>
                          {" "}
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt={`pimath-${uuidv4()}`}
                              className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                            ></img>
                          )}
                        </div>
                        <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                          <div className="flex items-center">
                            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white font-bold">
                              A
                            </div>
                            <span className="ml-3">
                              <MathRenderer content={q?.contentAnswerA} />
                            </span>
                          </div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-green-500 text-white font-bold">
                              B
                            </div>
                            <span className="ml-3">
                              <MathRenderer content={q?.contentAnswerB} />
                            </span>
                          </div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-yellow-500 text-white font-bold">
                              C
                            </div>
                            <span className="ml-3">
                              <MathRenderer content={q?.contentAnswerC} />
                            </span>
                          </div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-red-500 text-white font-bold">
                              D
                            </div>
                            <span className="ml-3">
                              <MathRenderer content={q?.contentAnswerD} />
                            </span>
                          </div>
                          <div className="mt-5">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Chọn đáp án"
                              color="success"
                              focused
                              disabled
                              name={question}
                              value={userAnswers[question] || ""}
                              className="md:w-[50%] w-[100%] text-center text-bold"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              {["A", "B", "C", "D"].map((value) => (
                                <MenuItem key={value} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </div>
                        <p className="mt-2 font-bold">
                          Kết quả:{" "}
                          <span
                            className={
                              answers?.[question] === true
                                ? "text-emerald-600"
                                : "text-red-700"
                            }
                          >
                            {answers?.[question] === true ? "Đúng" : "Sai"}
                          </span>
                        </p>
                      </div>
                    );
                  }

                  if (type === "DS") {
                    return (
                      <div
                        key={index + 1}
                        id={question}
                        className="mx-auto relative mt-4 page md:p-[30px] p-[10px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
                      >
                        <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                          {question}:
                        </h2>
                        <div className="question-content">
                          <MathRenderer content={contentQuestions} />
                        </div>
                        <div>
                          {" "}
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt={`pimath-${uuidv4()}`}
                              className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                            ></img>
                          )}
                        </div>
                        <div className="overflow-x-auto md:max-w-[100%] max-w-[400px] m-auto">
                          <table className="table-contaiter styled-table">
                            <thead>
                              <tr>
                                <th rowSpan="2" className="question">
                                  Mệnh đề
                                </th>
                                <th colSpan="2" className="text-center">
                                  Nội dung
                                </th>
                                <th rowSpan="2" className="correct">
                                  Đúng
                                </th>
                                <th rowSpan="2" className="wrong ">
                                  Sai
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {["a", "b", "c", "d"].map((option, index) => {
                                const content = [
                                  q?.contentYA,
                                  q?.contentYB,
                                  q?.contentYC,
                                  q?.contentYD,
                                ][index];
                                if (content) {
                                  return (
                                    <tr key={option}>
                                      <td className="question">{option})</td>
                                      <td className="statement" colSpan="2">
                                        <MathRenderer content={content} />
                                      </td>
                                      <td className="correct">
                                        <Checkbox
                                          disabled
                                          checked={
                                            userAnswers[question][option] ===
                                            "D"
                                          }
                                        />
                                      </td>
                                      <td className="wrong">
                                        <Checkbox
                                          disabled
                                          checked={
                                            userAnswers[question][option] ===
                                            "S"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                        <p className="mt-2 font-bold">
                          Kết quả:{" "}
                          <span
                            className={
                              answers?.[question] === true
                                ? "text-emerald-600"
                                : "text-red-700"
                            }
                          >
                            {answers?.[question] === true ? "Đúng" : "Sai"}
                          </span>
                        </p>
                      </div>
                    );
                  }

                  if (type === "KT") {
                    return (
                      <div
                        key={index + 1}
                        id={question}
                        className="mx-auto relative mt-4 page md:p-[30px] p-[10px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
                      >
                        <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                          {question}:
                        </h2>
                        <div className="question-content">
                          <MathRenderer content={q?.contentQuestions} />
                        </div>
                        <div>
                          {" "}
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt={`pimath-${uuidv4()}`}
                              className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                            ></img>
                          )}
                        </div>
                        <DragDropContext>
                          <div className="container mx-auto p-6">
                            <div key={q.question} className="mb-8">
                              <Droppable droppableId={q.question}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="p-2 rounded mt-2 min-h-[50px] lg:w-[50%] w-[100%] border-2 border-dashed border-teal-600"
                                  >
                                    <div className="flex flex-wrap lg:space-x-2">
                                      {q?.items?.map((item, index) => (
                                        <Draggable
                                          key={item?.id}
                                          draggableId={item?.id}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className={
                                                "bg-blue-500 text-white p-2 w-16 rounded text-center mr-2 lg:mt-0 mt-2"
                                              }
                                            >
                                              <MathRenderer
                                                content={item?.content}
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>

                              <div className="mt-4">
                                {q?.contentY1 && (
                                  <div className="flex items-center space-x-4 mb-4">
                                    <div className="flex">
                                      <strong>1) </strong>&nbsp; &nbsp;
                                      <MathRenderer content={q?.contentY1} />
                                    </div>
                                    <Droppable droppableId="slot1">
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                                        >
                                          {q?.answers?.slot1 && (
                                            <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                              <MathRenderer
                                                content={
                                                  q?.answers?.slot1?.content
                                                }
                                              />
                                            </div>
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                )}
                                {q?.contentY2 && (
                                  <div className="flex items-center space-x-4 mb-4">
                                    <div className="flex">
                                      <strong>2) </strong>&nbsp; &nbsp;
                                      <MathRenderer
                                        content={q?.contentY2}
                                      />{" "}
                                    </div>

                                    <Droppable droppableId="slot2">
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                                        >
                                          {q?.answers?.slot2 && (
                                            <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                              <MathRenderer
                                                content={
                                                  q?.answers?.slot2?.content
                                                }
                                              />
                                            </div>
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                )}

                                {q?.contentY3 && (
                                  <div className="flex items-center space-x-4 mb-4">
                                    <div className="flex">
                                      <strong>3) </strong>&nbsp; &nbsp;
                                      <MathRenderer content={q?.contentY3} />
                                    </div>

                                    <Droppable droppableId="slot3">
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                                        >
                                          {q?.answers?.slot3 && (
                                            <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                              <MathRenderer
                                                content={
                                                  q?.answers?.slot3?.content
                                                }
                                              />
                                            </div>
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                )}

                                {q?.contentY4 && (
                                  <div className="flex items-center space-x-4 mb-4">
                                    <div className="flex">
                                      <strong>4) </strong>&nbsp; &nbsp;
                                      <MathRenderer content={q?.contentY4} />
                                    </div>
                                    <Droppable droppableId="slot4">
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          className="p-2 rounded min-h-[50px] lg:w-[10%] w-[30%] border-2 border-dashed border-teal-600"
                                        >
                                          {q?.answers?.slot4 && (
                                            <div className="bg-blue-500 text-white p-2 w-16 rounded text-center">
                                              <MathRenderer
                                                content={
                                                  q?.answers?.slot4?.content
                                                }
                                              />
                                            </div>
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </DragDropContext>
                        <p className="mt-2 font-bold">
                          Kết quả:{" "}
                          <span
                            className={
                              answers?.[question] === true
                                ? "text-emerald-600"
                                : "text-red-700"
                            }
                          >
                            {answers?.[question] === true ? "Đúng" : "Sai"}
                          </span>
                        </p>
                      </div>
                    );
                  }

                  if (type === "TLN") {
                    return (
                      <div
                        key={index + 1}
                        id={question}
                        className="mx-auto relative mt-4 page md:p-[30px] p-[10px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
                      >
                        <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                          {question}:
                        </h2>
                        <div className="question-content">
                          <MathRenderer content={q?.contentQuestions} />
                        </div>
                        <div>
                          {" "}
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt={`pimath-${uuidv4()}`}
                              className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                            ></img>
                          )}
                        </div>
                        <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                          <div className="mt-5">
                            <TextField
                              label="Đáp án *"
                              type="text"
                              color="success"
                              focused
                              disabled
                              name={question}
                              className="text-center"
                              value={userAnswers[question] || ""}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                        <p className="mt-2 font-bold">
                          Kết quả:{" "}
                          <span
                            className={
                              answers?.[question] === true
                                ? "text-emerald-600"
                                : "text-red-700"
                            }
                          >
                            {answers?.[question] === true ? "Đúng" : "Sai"}
                          </span>
                        </p>
                      </div>
                    );
                  }

                  if (type === "TLN_M") {
                    return (
                      <div
                        key={index + 1}
                        id={question}
                        className="mx-auto relative mt-4 page md:p-[30px] p-[10px] bg-gray-300 hover:bg-gray-200 rounded-[24px] opacity-100 shadow-lg"
                      >
                        <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                          {question}:
                        </h2>
                        <div className="question-content">
                          <MathRenderer content={q?.contentQuestions} />
                        </div>
                        <div>
                          {" "}
                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt={`pimath-${uuidv4()}`}
                              className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                            ></img>
                          )}
                        </div>
                        {q?.contentY1 && (
                          <div className="mt-4 mb-4">
                            <div className="flex">
                              <strong>1.</strong>&nbsp; &nbsp;
                              <MathRenderer content={q?.contentY1} />
                            </div>

                            <div className="mt-5">
                              <TextField
                                label="Đáp án *"
                                type="text"
                                color="success"
                                disabled
                                focused
                                name={question + "-1."}
                                className="text-center"
                                value={userAnswers[question]["1."] || ""}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {q?.contentY2 && (
                          <div className="mb-4">
                            <div className="flex">
                              <strong>2.</strong>&nbsp; &nbsp;
                              <MathRenderer content={q?.contentY2} />
                            </div>

                            <div className="mt-5">
                              <TextField
                                label="Đáp án *"
                                type="text"
                                color="success"
                                focused
                                disabled
                                name={question + "-2."}
                                className="text-center"
                                value={userAnswers[question]["2."] || ""}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {q?.contentY3 && (
                          <div className="mb-4">
                            <div className="flex">
                              <strong>3.</strong>&nbsp; &nbsp;
                              <MathRenderer content={q?.contentY3} />
                            </div>

                            <div className="mt-5">
                              <TextField
                                label="Đáp án *"
                                type="text"
                                color="success"
                                focused
                                disabled
                                name={question + "-3."}
                                className="text-center"
                                value={userAnswers[question]["3."] || ""}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {q?.contentY4 && (
                          <div className="">
                            <div className="flex">
                              <strong>4.</strong>&nbsp; &nbsp;
                              <MathRenderer content={q?.contentY4} />
                            </div>
                            <div className="mt-5">
                              <TextField
                                label="Đáp án *"
                                type="text"
                                color="success"
                                focused
                                disabled
                                name={question + "-4."}
                                className="text-center"
                                value={userAnswers[question]["4."] || ""}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <p className="mt-2 font-bold">
                          Kết quả:{" "}
                          <span
                            className={
                              answers?.[question] === true
                                ? "text-emerald-600"
                                : "text-red-700"
                            }
                          >
                            {answers?.[question] === true ? "Đúng" : "Sai"}
                          </span>
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            {/* Floating Question Navigator Button */}
            <button
              className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg z-1000"
              onClick={() => setIsNavigatorOpen(!isNavigatorOpen)}
            >
              Chọn câu hỏi
            </button>

            {/* Floating Question Navigator */}
            {isNavigatorOpen && (
              <div className="fixed bottom-16 right-4 bg-white shadow-lg rounded-lg p-4 w-[18rem] max-h-96 overflow-y-auto z-1000">
                <h3 className="text-xl font-bold mb-3 text-center">Thống kê</h3>
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-sky-600 mr-2"></div>
                      <span>Đã trả lời</span>
                    </div>
                    <span>
                      {questions?.filter((q) => isAnswered(q.question))
                        .length || 0}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full  bg-yellow-400 mr-2"></div>
                      <span>Chưa chọn</span>
                    </div>
                    <span>
                      {questions?.filter((q) => !isAnswered(q.question))
                        .length || 0}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
                      <span>Trả lời đúng</span>
                    </div>
                    <span>
                      {questions?.filter((q) => answers?.[q.question]).length ||
                        0}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-red-500  mr-2"></div>
                      <span>Trả lời sai</span>
                    </div>
                    <span>
                      {questions?.filter((q) => !answers?.[q.question])
                        .length || 0}
                    </span>
                  </div>
                </div>

                <h3 className="text-lg font-bold mb-3 text-center mt-5">
                  Chọn câu hỏi
                </h3>
                <div className="grid grid-cols-5 gap-2">
                  {questions?.map((q, index) => {
                    const { question } = q;
                    return (
                      <button
                        key={index + 1}
                        className={`w-10 h-10 text-sm font-bold flex items-center justify-center rounded-md border transition-all
                  ${
                    currentQuestion === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 hover:bg-gray-300"
                  }
                  ${isAnswered(question) ? "font-extrabold bg-green-400" : ""}
                  ${
                    answers?.[question]
                      ? "font-extrabold bg-green-400"
                      : "bg-red-700"
                  }`}
                        onClick={() => scrollToQuestion(index + 1, question)}
                      >
                        {index + 1}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

export default ViewSelectedAnswer;
